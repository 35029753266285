"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateAmountValueConverter = void 0;
var TemplateAmountValueConverter = /** @class */ (function () {
    function TemplateAmountValueConverter() {
    }
    TemplateAmountValueConverter.prototype.toView = function (pageLimit, totalResults) {
        //if the page has less results than the max per page, set results number to the total
        if (pageLimit > totalResults) {
            pageLimit = totalResults;
        }
        return pageLimit;
    };
    return TemplateAmountValueConverter;
}());
exports.TemplateAmountValueConverter = TemplateAmountValueConverter;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecsSortValueConverter = void 0;
var SpecsSortValueConverter = /** @class */ (function () {
    function SpecsSortValueConverter() {
    }
    SpecsSortValueConverter.prototype.toView = function (array, propertyName, direction) {
        var breastElement;
        for (var i = array.length - 1; i >= 0; i--) {
            var element = array[i];
            if (element.code == 'BR') {
                array.splice(i, 1);
                array.push(element);
            }
        }
        //sort so specs with isFavorite = 1 come first
        array.sort(function (a, b) {
            if (a.isFavorite > b.isFavorite) {
                return -1;
            }
            if (b.isFavorite > a.isFavorite) {
                return 1;
            }
            return 0;
        });
        for (var i = 0; i < array.length; i++) {
            if (array[i].isFavorite == 1 && array[i + 1].isFavorite != 1) {
                array[i].isLastFavorite = true;
            }
        }
        return array;
    };
    return SpecsSortValueConverter;
}());
exports.SpecsSortValueConverter = SpecsSortValueConverter;

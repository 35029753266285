"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeElementSetView = exports.changeBaseUrl = exports.defocusAssignment = exports.focusAssignment = exports.defocusReview = exports.focusReview = exports.defocusSet = exports.focusSet = exports.defocusElement = exports.focusElement = void 0;
var store_1 = require("../store");
function focusElement(state, id) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.element.focusedElementId = id;
            return [2 /*return*/, newState];
        });
    });
}
exports.focusElement = focusElement;
function defocusElement(state) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.element.focusedElementId = null;
            newState.view.element.focusedElementVersion = null;
            return [2 /*return*/, newState];
        });
    });
}
exports.defocusElement = defocusElement;
function focusSet(state, id) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.set.focusedSetId = id;
            return [2 /*return*/, newState];
        });
    });
}
exports.focusSet = focusSet;
function defocusSet(state) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.set.focusedSetId = null;
            newState.view.set.focusedSetVersion = null;
            return [2 /*return*/, newState];
        });
    });
}
exports.defocusSet = defocusSet;
function focusReview(state, id) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.review.focusedReviewId = id;
            return [2 /*return*/, newState];
        });
    });
}
exports.focusReview = focusReview;
function defocusReview(state) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.review.focusedReviewId = null;
            newState.view.review.focusedReviewVersion = null;
            return [2 /*return*/, newState];
        });
    });
}
exports.defocusReview = defocusReview;
function focusAssignment(state, id) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.assignment.focusedAssignmentId = id;
            return [2 /*return*/, newState];
        });
    });
}
exports.focusAssignment = focusAssignment;
function defocusAssignment(state) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.assignment.focusedAssignmentId = null;
            newState.view.assignment.focusedAssignmentVersion = null;
            return [2 /*return*/, newState];
        });
    });
}
exports.defocusAssignment = defocusAssignment;
function changeBaseUrl(state, url) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.baseUrl = url;
            return [2 /*return*/, newState];
        });
    });
}
exports.changeBaseUrl = changeBaseUrl;
function changeElementSetView(state, tab) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.view.elementSetView = tab;
            return [2 /*return*/, newState];
        });
    });
}
exports.changeElementSetView = changeElementSetView;
store_1.default.registerAction('focusElement', focusElement);
store_1.default.registerAction('defocusElement', defocusElement);
store_1.default.registerAction('focusSet', focusSet);
store_1.default.registerAction('defocusSet', defocusSet);
store_1.default.registerAction('focusReview', focusReview);
store_1.default.registerAction('defocusReview', defocusReview);
store_1.default.registerAction('focusAssignment', focusAssignment);
store_1.default.registerAction('defocusAssignment', defocusAssignment);
store_1.default.registerAction('changeBaseUrl', changeBaseUrl);
store_1.default.registerAction('changeElementSetView', changeElementSetView);

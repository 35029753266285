"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferenceImageValueConverter = void 0;
var ReferenceImageValueConverter = /** @class */ (function () {
    function ReferenceImageValueConverter() {
    }
    ReferenceImageValueConverter.prototype.toView = function (referenceImage) {
        // if string has a image extension then return true else false
        var imageExtensions = ['jpg', 'JPG', 'png', 'PNG', 'jpeg', 'JPEG'];
        var urlSplit = [];
        if (referenceImage) {
            urlSplit = referenceImage.split('.');
        }
        return imageExtensions.includes(urlSplit[urlSplit.length - 1]);
    };
    return ReferenceImageValueConverter;
}());
exports.ReferenceImageValueConverter = ReferenceImageValueConverter;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginPrompt = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_router_1 = require("aurelia-router");
var aurelia_store_1 = require("aurelia-store");
var auth_1 = require("store/actions/auth");
var aurelia_dialog_1 = require("aurelia-dialog");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var auth_service_1 = require("services/auth-service");
var config_1 = require("config");
var customer_info_outdated_1 = require("components/modals/customer-info-outdated/customer-info-outdated");
var LoginPrompt = /** @class */ (function () {
    function LoginPrompt(router, controller, dialogService, authService, ea) {
        var _this = this;
        this.router = router;
        this.controller = controller;
        this.dialogService = dialogService;
        this.authService = authService;
        this.ea = ea;
        this.loginFail = false;
        this.username = '';
        this.password = '';
        this.loginOrganization = 'rsna';
        this.forgotPasswordLink = config_1.Configuration.getForgotPasswordLink();
        this.newAccountLink = config_1.Configuration.getNewAccountLink();
        this.enterKeySubmit = function (e) {
            var key = e.which || e.keyCode;
            if (key === 13) { // 13 is enter
                _this.login();
            }
        };
    }
    LoginPrompt.prototype.attached = function () {
        document.addEventListener('keypress', this.enterKeySubmit);
        document.getElementById('qa_username').focus();
    };
    LoginPrompt.prototype.detached = function () {
        document.removeEventListener('keypress', this.enterKeySubmit);
    };
    LoginPrompt.prototype.login = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loginData_1, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!((!this.username || !this.password) || (this.username === '' || this.password == ''))) return [3 /*break*/, 1];
                        this.loginFail = true;
                        return [2 /*return*/, false];
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.authService.loginRsna(this.username, this.password)];
                    case 2:
                        loginData_1 = _a.sent();
                        if (!loginData_1.TOKEN) return [3 /*break*/, 4];
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(auth_1.login)(loginData_1)];
                    case 3:
                        _a.sent();
                        this.controller.ok();
                        // If block for customers who have been flagged for outdated customer data(Greater than one year old)
                        if (loginData_1.CUSTOMER_INFO_NEEDS_UPDATING) {
                            this.dialogService.open({ viewModel: customer_info_outdated_1.CustomerInfoOutdated, model: { data: loginData_1.TOKEN }, lock: true }).whenClosed(function (response) {
                                if (!response.wasCancelled) {
                                    _this.Ww3CustomerDataRedirect(loginData_1.TOKEN);
                                }
                            });
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        this.loginFail = true;
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        console.log(err_1);
                        this.loginFail = true;
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    LoginPrompt.prototype.loginAcr = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.router.navigateToRoute('login', { logging_in: true });
                return [2 /*return*/];
            });
        });
    };
    // Redirect customer after successful login based on if they have been flagged for outdated customer data(Greater than one year old)
    // SSO/Token accessed via cookies in WW3, param passed just for verification redundancy
    LoginPrompt.prototype.Ww3CustomerDataRedirect = function (token) {
        var ww3CustomerDataURI = new URL(config_1.Configuration.getWw3URI() + '/customerdata/customer/');
        ww3CustomerDataURI.searchParams.append('token', token);
        window.location.href = String(ww3CustomerDataURI);
    };
    LoginPrompt = __decorate([
        (aurelia_framework_1.autoinject),
        (0, aurelia_store_1.connectTo)(),
        __metadata("design:paramtypes", [aurelia_router_1.Router, aurelia_dialog_1.DialogController, aurelia_dialog_1.DialogService, auth_service_1.AuthService, aurelia_event_aggregator_1.EventAggregator])
    ], LoginPrompt);
    return LoginPrompt;
}());
exports.LoginPrompt = LoginPrompt;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavBar = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_router_1 = require("aurelia-router");
var aurelia_dialog_1 = require("aurelia-dialog");
var aurelia_pal_1 = require("aurelia-pal");
var login_prompt_1 = require("../../login-prompt/login-prompt");
var cookie_1 = require("utils/cookie");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var auth_1 = require("store/actions/auth");
var auth_service_1 = require("services/auth-service");
var config_1 = require("config");
var NavBar = /** @class */ (function () {
    function NavBar(router, dialogService, authService) {
        this.router = router;
        this.dialogService = dialogService;
        this.authService = authService;
    }
    NavBar.prototype.attached = function () {
        aurelia_pal_1.PLATFORM.global.addEventListener('resize', this.toggleMobileDesktop);
        this.toggleMobileDesktop();
        if ((0, cookie_1.getCookie)('TOKEN') !== '') {
            (0, aurelia_store_1.dispatchify)(auth_1.getPreviousSession)((0, cookie_1.getCookie)('TOKEN'));
        }
    };
    NavBar.prototype.detached = function () {
        aurelia_pal_1.PLATFORM.global.removeEventListener('resize', this.toggleMobileDesktop);
    };
    NavBar.prototype.toggleMobileMenu = function () {
        document.getElementById('mobile-toggler').classList.toggle('mobile-icon-open');
        document.getElementById('content').classList.toggle('mobile-push-right');
        document.getElementById('left-mobile-nav').classList.toggle('mobile-nav-expanded');
        document.getElementById('footer').classList.toggle('mobile-push-right');
    };
    NavBar.prototype.toggleMobileDesktop = function () {
        if (window.matchMedia('(max-width: 992px)').matches) {
            document.getElementById('content').classList.add('mobile-content');
        }
        else {
            document.getElementById('content').classList.remove('mobile-content');
        }
    };
    NavBar.prototype.openLogin = function () {
        this.toggleMobileMenu();
        this.dialogService.open({ viewModel: login_prompt_1.LoginPrompt, model: null, lock: false }).whenClosed(function (response) {
            if (!response.wasCancelled) {
                // this.router.navigate(`#/${this.template.tablename}`);
            }
        });
    };
    NavBar.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var acrIdCookie;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.toggleMobileMenu();
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(auth_1.logout)()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, (0, cookie_1.deleteCookie)('SSO')];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, (0, cookie_1.deleteCookie)('TOKEN')];
                    case 3:
                        _a.sent();
                        acrIdCookie = (0, cookie_1.getCookie)('OKTA_ID_TOKEN');
                        if (acrIdCookie) {
                            window.location.href = "".concat(config_1.Configuration.getOktaBaseURI(), "/logout?id_token_hint=").concat(acrIdCookie, "&post_logout_redirect_uri=").concat(config_1.Configuration.getAppURI());
                        }
                        this.router.navigateToRoute('home', {}, { replace: true, trigger: true });
                        return [2 /*return*/];
                }
            });
        });
    };
    NavBar.prototype.profilePage = function (pageName) {
        this.router.navigate('#/profile/' + pageName);
    };
    NavBar = __decorate([
        (aurelia_framework_1.autoinject),
        (0, aurelia_store_1.connectTo)({
            selector: {
                authState: function (store) { return store.state.pipe((0, operators_1.pluck)('auth')); },
            }
        }),
        __metadata("design:paramtypes", [aurelia_router_1.Router, aurelia_dialog_1.DialogService, auth_service_1.AuthService])
    ], NavBar);
    return NavBar;
}());
exports.NavBar = NavBar;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationButtonsValueConverter = void 0;
var PaginationButtonsValueConverter = /** @class */ (function () {
    function PaginationButtonsValueConverter() {
    }
    PaginationButtonsValueConverter.prototype.toView = function (paginationData) {
        //math for setting pages
        var firstShownPageNumber = 1;
        var lastShownPageNumber = paginationData.last_page;
        if (paginationData.last_page > 10) {
            //start shifting numbers on buttons after page 5
            if (paginationData.current_page > 5) {
                //if page = 6 the first button shown will be for page 2
                firstShownPageNumber = paginationData.current_page - 4;
            }
            if (paginationData.current_page < lastShownPageNumber - 5) {
                //if in the middle of the pages, show 9 after the first page
                lastShownPageNumber = firstShownPageNumber + 9;
            }
            else {
                //if close to end of pages, show the 9 before the end
                firstShownPageNumber = lastShownPageNumber - 9;
            }
        }
        var numberArray = [];
        while (firstShownPageNumber <= lastShownPageNumber) {
            numberArray.push(firstShownPageNumber++);
        }
        return numberArray;
    };
    return PaginationButtonsValueConverter;
}());
exports.PaginationButtonsValueConverter = PaginationButtonsValueConverter;

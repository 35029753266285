"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Configuration = void 0;
var aurelia_plugins_cookies_1 = require("aurelia-plugins-cookies");
var Configuration = /** @class */ (function () {
    function Configuration() {
        this.home = true;
    }
    Configuration.getApplicationName = function () {
        return Configuration.appName;
    };
    Configuration.getWw3URI = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            // Replace with your local machine URL
            return (Configuration.ww3appURI = 'http://www3dev.rsna.org');
        }
        else if (WEBPACK_ENV === 'stage') {
            return (Configuration.ww3appURI = 'https://www3stage.rsna.org');
        }
        return Configuration.ww3appURI;
    };
    Configuration.getAppURI = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            Configuration.appURI = 'http://dev.radelement.org';
        }
        else if (WEBPACK_ENV === 'stage') {
            Configuration.appURI = 'https://stage.radelement.org';
        }
        return Configuration.appURI;
    };
    Configuration.getBaseURI = function (basepath) {
        if (basepath === void 0) { basepath = 'radelement/v1'; }
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            Configuration.baseURI = 'https://api3dev.rsna.org';
        }
        else if (WEBPACK_ENV === 'stage') {
            Configuration.baseURI = 'https://api3stage.rsna.org';
        }
        else {
            // must be prod (was giving odd behavior in testing with persisting on stage )
            Configuration.baseURI = 'https://api3.rsna.org';
        }
        return Configuration.baseURI + '/' + basepath;
    };
    Configuration.gets3URI = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            return (Configuration.s3URI =
                'https://s3.amazonaws.com/dev.radelement.org');
        }
        else if (WEBPACK_ENV === 'stage') {
            return (Configuration.s3URI =
                'https://s3.amazonaws.com/stage.radelement.org');
        }
        else {
            // must be prod (was giving odd behavior in testing with persisting on stage )
            return (Configuration.s3URI =
                'https://s3.amazonaws.com/radelement.org');
        }
    };
    Configuration.getOktaClientId = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            Configuration.oktaClientId = '0oapakzb7ciX9794t0h7';
        }
        else if (WEBPACK_ENV === 'stage') {
            Configuration.oktaClientId = '0oa5cxvo59cepPB8C357';
        }
        return Configuration.oktaClientId;
    };
    // NOTE: ACR Okta Admin URL - keep an eye on this since it may change
    Configuration.getOktaBaseURI = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            Configuration.oktaBaseURI =
                'https://acr-admin.oktapreview.com/admin/app/oidc_client/instance/';
        }
        else if (WEBPACK_ENV === 'stage') {
            Configuration.oktaBaseURI = 'https://sso.acr.org/oauth2/default/v1';
        }
        return Configuration.oktaBaseURI;
    };
    Configuration.getSingleSignOn = function () {
        var sso = aurelia_plugins_cookies_1.Cookies.get('SSO');
        // Only encode if sso is present. Otherwise
        // will return null, which evals to both true
        // and false (it's undefined, actually).
        if (!!sso) {
            return encodeURIComponent(aurelia_plugins_cookies_1.Cookies.get('SSO'));
        }
        else {
            return sso;
        }
    };
    Configuration.getForgotPasswordLink = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            Configuration.forgotPasswordLink =
                'https://www2dev.rsna.org/shared_society_pages/forgotpassword/';
        }
        else if (WEBPACK_ENV === 'stage') {
            Configuration.forgotPasswordLink =
                'https://www2stage.rsna.org/shared_society_pages/forgotpassword/';
        }
        return Configuration.forgotPasswordLink;
    };
    Configuration.getNewAccountLink = function () {
        if (WEBPACK_ENV === 'dev' || WEBPACK_ENV === 'local') {
            Configuration.newAccountLink =
                'https://www2dev.rsna.org/shared_society_pages/CreateAccount/';
        }
        else if (WEBPACK_ENV === 'stage') {
            Configuration.newAccountLink =
                'https://www2stage.rsna.org/shared_society_pages/CreateAccount/';
        }
        return Configuration.newAccountLink;
    };
    Configuration.getHttp = function (http) {
        // encodeURI header value see https://www.owasp.org/index.php/Injection_Theory
        return http.configure(function (config) {
            config
                .useStandardConfiguration()
                .withBaseUrl(Configuration.getBaseURI())
                .withDefaults({
                headers: {
                    SSO: Configuration.getSingleSignOn(), // 'uW%2Bxdh3SDsHvOxcW%2BU1oVA%3D%3D',
                },
            });
        });
    };
    // Init for production
    Configuration.appName = 'RadElement';
    Configuration.appURI = 'https://www.radelement.org';
    Configuration.baseURI = 'https://api3.rsna.org';
    Configuration.ww3appURI = 'https://www3.rsna.org';
    Configuration.forgotPasswordLink = 'https://www2.rsna.org/shared_society_pages/forgotpassword/';
    Configuration.newAccountLink = 'https://www2.rsna.org/shared_society_pages/CreateAccount/';
    Configuration.basePath = 'radelement/v1';
    Configuration.updateProfileLink = 'https://www2.rsna.org/timssnet/demographics/tnt_cusupdate.cfm';
    Configuration.oktaClientId = '0oa5cxvo59cepPB8C357';
    Configuration.oktaBaseURI = 'https://sso.acr.org/oauth2/default/v1';
    Configuration.loginLink = 'https://www2.rsna.org/timssnet/login/caslogin.cfm?referrer_path=' +
        Configuration.getAppURI();
    Configuration.logoutLink = 'https://www2.rsna.org/timssnet/reset.cfm';
    Configuration.s3URI = 'https://s3.amazonaws.com/radelement.org';
    return Configuration;
}());
exports.Configuration = Configuration;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadCdes = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_store_1 = require("aurelia-store");
var aurelia_dialog_1 = require("aurelia-dialog");
var download_service_1 = require("services/download-service");
var email_service_1 = require("services/email-service");
require("../customer-info-outdated/customer-info-outdated.css");
var operators_1 = require("rxjs/operators");
var config_1 = require("config");
var DownloadCdes = /** @class */ (function () {
    function DownloadCdes(controller, downloadService, emailService) {
        this.controller = controller;
        this.downloadService = downloadService;
        this.emailService = emailService;
        this.download = []; // Array of CDE IDs to send to the downloader
        this.selectedIds = [];
        this.selectedElements = [];
        this.selectedSets = [];
        this.unavailableRTFFiles = [];
        // Do nothing
    }
    // When this component is activated it expects a list of selected CDEs
    DownloadCdes.prototype.activate = function (selectedDownloads) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.download = selectedDownloads;
                        selectedDownloads = [];
                        _a = this;
                        return [4 /*yield*/, this.getListOfUnavailableDownloads(this.download)];
                    case 1:
                        _a.unavailableRTFFiles = _b.sent();
                        if (!this.isUnavailableRTFFilesNotEmpty()) return [3 /*break*/, 3];
                        // there are items missing that we should alert staff of, send an email to ourselves with the list
                        return [4 /*yield*/, this.emailService.sendMissingCDEEmailToStaff(this.unavailableRTFFiles)];
                    case 2:
                        // there are items missing that we should alert staff of, send an email to ourselves with the list
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        // Copy CDE IDs into
                        this.download.forEach(function (cde) {
                            // Push CDE to either sets or elements array based on ID prefix
                            if (_this.isSet(cde['id']) &&
                                !_this.unavailableRTFFiles.includes(cde['id'])) {
                                _this.selectedSets.push(cde['id']);
                            }
                            else if (!_this.unavailableRTFFiles.includes(cde['id'])) {
                                _this.selectedElements.push(cde['id']);
                            }
                            // Push ID to selectedIds for iterating in html
                            _this.selectedIds.push(cde['id']);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    // Function to call downloader API.
    DownloadCdes.prototype.downloadFile = function () {
        // Make call to API3 to fetch document
        this.sendDownloadRequest();
        //Close modal afterwards
        this.controller.ok();
    };
    DownloadCdes.prototype.getListOfUnavailableDownloads = function (downloads) {
        return __awaiter(this, void 0, void 0, function () {
            var unavailableDownloads, _a, _b, _c, _i, index, basePath, cdeAvailablity, error_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        unavailableDownloads = [];
                        _a = downloads;
                        _b = [];
                        for (_c in _a)
                            _b.push(_c);
                        _i = 0;
                        _d.label = 1;
                    case 1:
                        if (!(_i < _b.length)) return [3 /*break*/, 6];
                        _c = _b[_i];
                        if (!(_c in _a)) return [3 /*break*/, 5];
                        index = _c;
                        basePath = "".concat(config_1.Configuration.gets3URI(), "/assets/psfieldgenerate/");
                        // Build URL for request based on assets or sets directory
                        if (this.isSet(downloads[index]['id'])) {
                            basePath += 'sets/';
                        }
                        else {
                            basePath += 'elements/';
                        }
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, fetch(basePath + downloads[index]['id'] + '.rtf')];
                    case 3:
                        cdeAvailablity = _d.sent();
                        // Push CDE to unavailable array if response is not 'ok'
                        if (!cdeAvailablity.ok) {
                            unavailableDownloads.push(downloads[index]['id']);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _d.sent();
                        // Push ID to array of unavailable files if there is another error
                        unavailableDownloads.push(downloads[index]['id']);
                        return [3 /*break*/, 5];
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/, unavailableDownloads];
                }
            });
        });
    };
    DownloadCdes.prototype.isSet = function (inputString) {
        return inputString.includes('RDES');
    };
    DownloadCdes.prototype.isFileUnavailable = function (CDEId) {
        return this.unavailableRTFFiles.some(function (unavailableFile) { return unavailableFile === CDEId; });
    };
    DownloadCdes.prototype.isUnavailableRTFFilesNotEmpty = function () {
        return this.unavailableRTFFiles.length > 0;
    };
    // Function to send download request
    DownloadCdes.prototype.sendDownloadRequest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = [];
                        // @ts-ignore
                        data['sets'] = this.selectedSets;
                        // @ts-ignore
                        data['elements'] = this.selectedElements;
                        return [4 /*yield*/, this.downloadService.download(data)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DownloadCdes = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)({
            selector: {
                filterState: function (store) { return store.state.pipe((0, operators_1.pluck)('filter')); },
            },
        }),
        __metadata("design:paramtypes", [aurelia_dialog_1.DialogController,
            download_service_1.DownloadService,
            email_service_1.EmailService])
    ], DownloadCdes);
    return DownloadCdes;
}());
exports.DownloadCdes = DownloadCdes;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = exports.validateState = void 0;
function validateState(state) {
    return 'auth' in state
        && 'view' in state
        && 'filter' in state
        && 'okta' in state
        // auth
        && 'token' in state.auth
        && 'email' in state.auth
        && 'id' in state.auth
        && 'userId' in state.auth
        && 'firstName' in state.auth
        && 'lastName' in state.auth
        && 'codeVerifier' in state.okta
        // view
        && 'element' in state.view
        && 'set' in state.view
        && 'review' in state.view
        && 'assignment' in state.view
        && 'allcdes' in state.view
        && 'baseUrl' in state.view
        && 'elementSetView' in state.view
        && 'focusedElementId' in state.view.element
        && 'focusedElementVersion' in state.view.element
        && 'focusedSetId' in state.view.set
        && 'focusedSetVersion' in state.view.set
        && 'focusedAllcdesId' in state.view.set
        && 'focusedAllcdesVersion' in state.view.set
        && 'focusedReviewId' in state.view.review
        && 'focusedReviewVersion' in state.view.review
        && 'focusedAssignmentId' in state.view.assignment
        && 'focusedAssignmentVersion' in state.view.assignment
        // filter
        && 'search' in state.filter
        && 'specialties' in state.filter
        && 'organizations' in state.filter
        && 'bodyParts' in state.filter
        && 'showPublishedOnly' in state.filter;
}
exports.validateState = validateState;
exports.initialState = {
    auth: {
        token: '',
        email: '',
        id: '',
        userId: '',
        firstName: '',
        lastName: ''
    },
    okta: {
        codeVerifier: ''
    },
    view: {
        element: {
            focusedElementId: null,
            focusedElementVersion: null
        },
        set: {
            focusedSetId: null,
            focusedSetVersion: null
        },
        allcdes: {
            focusedAllcdesId: null,
            focusedAllcdesVersion: null
        },
        review: {
            focusedReviewId: null,
            focusedReviewVersion: null
        },
        assignment: {
            focusedAssignmentId: null,
            focusedAssignmentVersion: null
        },
        baseUrl: '',
        elementSetView: 'allcdes'
    },
    filter: {
        search: '',
        specialties: [],
        organizations: [],
        bodyParts: [],
        showPublishedOnly: true,
        downloadAll: false
    }
};

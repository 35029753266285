"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableLimitValueConverter = void 0;
var TableLimitValueConverter = /** @class */ (function () {
    function TableLimitValueConverter() {
    }
    TableLimitValueConverter.prototype.toView = function (array, pageNum, pageSize) {
        var start = pageNum * pageSize - pageSize;
        var end = pageNum * pageSize;
        return array.slice(start, end);
    };
    return TableLimitValueConverter;
}());
exports.TableLimitValueConverter = TableLimitValueConverter;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayToStringValueConverter = void 0;
var ArrayToStringValueConverter = /** @class */ (function () {
    function ArrayToStringValueConverter() {
    }
    ArrayToStringValueConverter.prototype.toView = function (array, param) {
        if (!param) {
            return array.toString();
        }
        else {
            return array.map(function (item) {
                return ' ' + item[param];
            });
        }
    };
    return ArrayToStringValueConverter;
}());
exports.ArrayToStringValueConverter = ArrayToStringValueConverter;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configure = void 0;
var aurelia_pal_1 = require("aurelia-pal");
function configure(config) {
    config.globalResources([
        aurelia_pal_1.PLATFORM.moduleName('./pages/home/home'),
        aurelia_pal_1.PLATFORM.moduleName('./pages/about/about'),
        aurelia_pal_1.PLATFORM.moduleName('./pages/profile/profile'),
        aurelia_pal_1.PLATFORM.moduleName('./profile-pages/favorites/favorites-page'),
        aurelia_pal_1.PLATFORM.moduleName('./review/endorse/endorse-review'),
        aurelia_pal_1.PLATFORM.moduleName('./review/assign/assign-review'),
        aurelia_pal_1.PLATFORM.moduleName('./review/grade/grade-review'),
        aurelia_pal_1.PLATFORM.moduleName('./loading-spinner/loading-spinner'),
        aurelia_pal_1.PLATFORM.moduleName('./specialty-sidebar/specialty-sidebar'),
        aurelia_pal_1.PLATFORM.moduleName('./nav-menu/navbar/nav-bar'),
        aurelia_pal_1.PLATFORM.moduleName('./nav-menu/footer/footer'),
        aurelia_pal_1.PLATFORM.moduleName('./nav-menu/search/search'),
        aurelia_pal_1.PLATFORM.moduleName('./pagination/pagination'),
        aurelia_pal_1.PLATFORM.moduleName('./autocomplete/autocomplete'),
        aurelia_pal_1.PLATFORM.moduleName('./nav-menu/nav-menu'),
        aurelia_pal_1.PLATFORM.moduleName('./tables/all-cdes-table/all-cdes-table'),
        aurelia_pal_1.PLATFORM.moduleName('./tables/sets-table/sets-table'),
        aurelia_pal_1.PLATFORM.moduleName('./tables/elements-table/elements-table'),
        aurelia_pal_1.PLATFORM.moduleName('./tables/assignments-table/assignments-table'),
        aurelia_pal_1.PLATFORM.moduleName('./tables/assign-table/assign-table'),
        aurelia_pal_1.PLATFORM.moduleName('./tables/endorse-table/endorse-table'),
        aurelia_pal_1.PLATFORM.moduleName('./tables/favorites-table/favorites-table'),
        aurelia_pal_1.PLATFORM.moduleName('./details/element-details/element-details'),
        aurelia_pal_1.PLATFORM.moduleName('./details/set-details/set-details'),
        aurelia_pal_1.PLATFORM.moduleName('./details/review-details/review-details'),
        // value converters
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/pagination-buttons'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/limit-data'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/date-converter'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/boolean-to-string'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/format-array-string'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/summarize-endorsement'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/specs-sort'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/table-limit'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/template-amount'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/version-number'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/sort-reviews'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/array-to-string'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/reference-type-filter'),
        aurelia_pal_1.PLATFORM.moduleName('../value-converters/reference-image'),
    ]);
}
exports.configure = configure;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.allcdesService = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_fetch_client_1 = require("aurelia-fetch-client");
var config_1 = require("../config");
var error_handler_1 = require("utils/error-handler");
var allcdesService = /** @class */ (function () {
    function allcdesService(http) {
        this.http = http;
    }
    allcdesService.prototype.getSetsAndElements = function (pageNumber, filters, sort) {
        return __awaiter(this, void 0, void 0, function () {
            var path, specialtyString, organizationString, partString;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = "/allcdes?page=".concat(pageNumber, "&sort=").concat(sort.sortBy, "&order=").concat(sort.order, "&meta=true&web_view=true");
                        if (filters.search) {
                            path += "&search=".concat(filters.search);
                        }
                        if (filters.specialties.length) {
                            specialtyString = filters.specialties.join(',');
                            path += "&specialties=".concat(specialtyString);
                        }
                        if (filters.organizations.length) {
                            organizationString = filters.organizations.join(',');
                            path += "&organizations=".concat(organizationString);
                        }
                        if (filters.bodyParts.length) {
                            partString = filters.bodyParts.join(',');
                            path += "&body_parts=".concat(partString);
                        }
                        if (filters.showPublishedOnly) {
                            path += "&published_only=".concat(filters.showPublishedOnly);
                        }
                        if (filters.downloadAll) {
                            path += "&download_all=".concat(filters.downloadAll);
                        }
                        path = config_1.Configuration.getBaseURI() + path;
                        return [4 /*yield*/, this.http.fetch(path, {
                                method: 'GET',
                            })
                                .then(function (response) { return response.json(); })
                                .catch(function (err) { return new error_handler_1.default(err); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    allcdesService = __decorate([
        (0, aurelia_framework_1.autoinject)(),
        __metadata("design:paramtypes", [aurelia_fetch_client_1.HttpClient])
    ], allcdesService);
    return allcdesService;
}());
exports.allcdesService = allcdesService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateConverterValueConverter = void 0;
var moment = require("moment");
var DateConverterValueConverter = /** @class */ (function () {
    function DateConverterValueConverter() {
    }
    DateConverterValueConverter.prototype.toView = function (value) {
        var date = moment(value);
        if (date.isValid()) {
            return moment(value).format('M/D/YYYY');
        }
        else {
            return '0/0/0000';
        }
    };
    return DateConverterValueConverter;
}());
exports.DateConverterValueConverter = DateConverterValueConverter;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerInfoOutdated = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_store_1 = require("aurelia-store");
var aurelia_dialog_1 = require("aurelia-dialog");
require("../customer-info-outdated/customer-info-outdated.css");
var CustomerInfoOutdated = /** @class */ (function () {
    function CustomerInfoOutdated(controller) {
        this.controller = controller;
        // Do nothing
    }
    CustomerInfoOutdated.prototype.attached = function () {
        document.getElementsByTagName('ux-dialog-container')[1].setAttribute('id', 'customer-info-outdated-container');
    };
    CustomerInfoOutdated = __decorate([
        (aurelia_framework_1.autoinject),
        (0, aurelia_store_1.connectTo)(),
        __metadata("design:paramtypes", [aurelia_dialog_1.DialogController])
    ], CustomerInfoOutdated);
    return CustomerInfoOutdated;
}());
exports.CustomerInfoOutdated = CustomerInfoOutdated;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecialtySidebar = void 0;
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_framework_1 = require("aurelia-framework");
var filter_service_1 = require("services/filter-service");
var filter_1 = require("store/actions/filter");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var aurelia_router_1 = require("aurelia-router");
var SpecialtySidebar = /** @class */ (function () {
    function SpecialtySidebar(ea, filterService, bindingEngine, router) {
        var _this = this;
        this.ea = ea;
        this.filterService = filterService;
        this.bindingEngine = bindingEngine;
        this.router = router;
        this.specialties = [];
        this.showPublishedToggleSubscription = this.ea.subscribe('showPublishedToggle', function (loading) {
            _this.getSpecialty();
        });
        this.filterState = {};
    }
    SpecialtySidebar.prototype.bind = function () {
        this.getSpecialty();
    };
    SpecialtySidebar.prototype.detached = function () {
        this.showPublishedToggleSubscription.dispose();
    };
    SpecialtySidebar.prototype.setSpecialtyFilter = function (specialtyCode) {
        return __awaiter(this, void 0, void 0, function () {
            var searchType, searchType;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.filterState.specialties.length == 1 && specialtyCode == this.filterState.specialties[0])) return [3 /*break*/, 2];
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(filter_1.clearAllFilters)()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 2: return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(filter_1.clearAllFilters)()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(filter_1.setSpecialtyFilters)([specialtyCode])];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        if (this.viewState.elementSetView == 'sets' || this.viewState.elementSetView == 'elements') {
                            this.router.navigate("home/".concat(this.viewState.elementSetView));
                            searchType = this.viewState.elementSetView == 'sets' ? 'setSearch' : 'elementSearch';
                            this.ea.publish(searchType);
                        }
                        else {
                            this.router.navigate("/home");
                            searchType = 'allcdesSearch';
                            this.ea.publish(searchType);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpecialtySidebar.prototype.getSpecialty = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // force override the local storage specialties with updated param to ensure we get the latest data per the showPublishedOnly filter selection
                        _a = this;
                        return [4 /*yield*/, this.filterService.getSpecialty(this.filterState.showPublishedOnly, true)];
                    case 1:
                        // force override the local storage specialties with updated param to ensure we get the latest data per the showPublishedOnly filter selection
                        _a.specialties = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpecialtySidebar = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)({
            selector: {
                filterState: function (store) { return store.state.pipe((0, operators_1.pluck)('filter')); },
                viewState: function (store) { return store.state.pipe((0, operators_1.pluck)('view')); }
            }
        }),
        __metadata("design:paramtypes", [aurelia_event_aggregator_1.EventAggregator, filter_service_1.FilterService, aurelia_framework_1.BindingEngine, aurelia_router_1.Router])
    ], SpecialtySidebar);
    return SpecialtySidebar;
}());
exports.SpecialtySidebar = SpecialtySidebar;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VersionNumberValueConverter = void 0;
var VersionNumberValueConverter = /** @class */ (function () {
    function VersionNumberValueConverter() {
    }
    VersionNumberValueConverter.prototype.toView = function (versions) {
        if (versions) {
            for (var i = 0; i < versions.length; i++) {
                versions[i].versionNumber = versions.length - i;
            }
        }
        return versions;
    };
    return VersionNumberValueConverter;
}());
exports.VersionNumberValueConverter = VersionNumberValueConverter;

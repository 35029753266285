"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.configure = void 0;
var aurelia_pal_1 = require("aurelia-pal");
var Bluebird = require("bluebird");
require("bootstrap/dist/js/bootstrap.bundle.min.js");
require("./bootstrap.min.css");
require("./css/radelement.scss");
require("./css/bootstrap-override.scss");
var state_1 = require("./store/state");
// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({ warnings: false, longStackTraces: false });
function configure(aurelia) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    aurelia.use
                        .standardConfiguration()
                        .developmentLogging()
                        .feature(aurelia_pal_1.PLATFORM.moduleName('components/index'))
                        .plugin(aurelia_pal_1.PLATFORM.moduleName('aurelia-dialog'))
                        .plugin(aurelia_pal_1.PLATFORM.moduleName('au-table'))
                        .plugin(aurelia_pal_1.PLATFORM.moduleName('aurelia-store'), {
                        initialState: state_1.initialState,
                    });
                    if (WEBPACK_ENV !== 'dev') {
                        aurelia.use.plugin(aurelia_pal_1.PLATFORM.moduleName('aurelia-google-analytics'), function (config) {
                            config.init(TRACKING_CODE);
                            config.attach({
                                logging: {
                                    // Set to `true` to have some log messages appear in the browser console.
                                    enabled: WEBPACK_ENV === 'stage',
                                },
                                pageTracking: {
                                    // Set to `false` to disable in non-production environments.
                                    enabled: true,
                                    // Configure fragments/routes/route names to ignore page tracking for
                                    ignore: {
                                        fragments: [],
                                        routes: [],
                                        routeNames: [] // Ignore a route name, login route name for example: ['login-route']
                                    },
                                    // Optional. By default it gets the title from payload.instruction.config.title.
                                    /*
                                    getTitle: (payload) => {
                                        // For example, if you want to retrieve the tile from the document instead override with the following.
                                        return document.title;
                                    },
                                    */
                                    // Optional. By default it gets the URL fragment from payload.instruction.fragment.
                                    /*
                                    getUrl: (payload) => {
                                        // For example, if you want to get full URL each time override with the following.
                                        return window.location.href;
                                    }
                                    */
                                },
                                clickTracking: {
                                    // Set to `false` to disable in non-production environments.
                                    enabled: true,
                                    // Optional. By default it tracks clicks on anchors and buttons.
                                    /*
                                    filter: (element) => {
                                        // For example, if you want to also track clicks on span elements override with the following.
                                        return element instanceof HTMLElement &&
                                            (element.nodeName.toLowerCase() === 'a' ||
                                                element.nodeName.toLowerCase() === 'button' ||
                                                element.nodeName.toLowerCase() === 'span');
                                    }
                                    */
                                },
                                exceptionTracking: {
                                    // Set to `false` to disable in non-production environments.
                                    enabled: true,
                                },
                            });
                        });
                    }
                    return [4 /*yield*/, aurelia.start()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, aurelia.setRoot(aurelia_pal_1.PLATFORM.moduleName('app'))];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.configure = configure;

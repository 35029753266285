"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadService = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_fetch_client_1 = require("aurelia-fetch-client");
var config_1 = require("../config");
var DownloadService = /** @class */ (function () {
    function DownloadService(http) {
        this.http = http;
    }
    /**
     *
     * @param {any} data - An array containing two subarrays containing IDs of Sets and Elements listed for download
     * @param {string} filename - the default filename for the download
     * if single item is being downloaded is should be the name of the set/element selected.
     * if multiple items are selected, then use default name for .zip containing items files
     * @return {void}
     */
    DownloadService.prototype.download = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, pathPrefix, filename, path;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.pathAndFileNameHandler(data), pathPrefix = _a[0], filename = _a[1];
                        path = config_1.Configuration.getBaseURI('document/v1') + pathPrefix;
                        return [4 /*yield*/, this.http
                                .fetch(path, {
                                method: 'POST',
                                body: (0, aurelia_fetch_client_1.json)({
                                    sets: data['sets'],
                                    elements: data['elements'],
                                }),
                            })
                                .then(function (Response) {
                                return Response.blob();
                            })
                                .then(function (data) {
                                var blob = _this.getBlob(data);
                                if (window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(blob, filename);
                                }
                                else {
                                    var blobUrl = window.URL.createObjectURL(blob);
                                    var anchor = document.createElement('a');
                                    anchor.download = filename;
                                    anchor.href = blobUrl;
                                    document.body.appendChild(anchor);
                                    anchor.click();
                                    document.body.removeChild(anchor);
                                }
                            })
                                .catch(function (err) {
                                alert(err);
                            })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    DownloadService.prototype.getBlob = function (data) {
        var blob = null;
        blob = new Blob([data], {
            type: 'octet/stream',
        });
        return blob;
    };
    // Helper to dynamically set path and filename based on array length
    DownloadService.prototype.pathAndFileNameHandler = function (data) {
        // Filename set for single items on init
        var filename = data['sets'][0] ? data['sets'][0] : data['elements'][0];
        var path = '/download';
        var totalCount = data['sets'].length + data['elements'].length;
        // ZIP
        if (totalCount > 1) {
            filename = 'RadElementCdes.zip'; // overwrite filename , if we have multiple use this default name
            path += '/zip/rad/rad-element';
            //RTF
        }
        else {
            filename += '.rtf'; // append filetype to file name from selected item
            path += '/rtf/rad/rad-element';
        }
        return [path, filename];
    };
    DownloadService = __decorate([
        (0, aurelia_framework_1.autoinject)(),
        __metadata("design:paramtypes", [aurelia_fetch_client_1.HttpClient])
    ], DownloadService);
    return DownloadService;
}());
exports.DownloadService = DownloadService;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LimitDataValueConverter = void 0;
var LimitDataValueConverter = /** @class */ (function () {
    function LimitDataValueConverter() {
    }
    LimitDataValueConverter.prototype.toView = function (dataArray, limit) {
        //if a limit is set, only show that many values in a array
        if (limit && dataArray) {
            return dataArray.slice(0, limit);
        }
        else {
            return dataArray;
        }
    };
    return LimitDataValueConverter;
}());
exports.LimitDataValueConverter = LimitDataValueConverter;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setShowPublishedFilter = exports.clearAllFilters = exports.clearOrganizationFilters = exports.removeOneBodyPartFilter = exports.setBodyPartFilters = exports.clearBodyPartFilters = exports.removeOneOrganizationFilter = exports.setOrganizationFilters = exports.clearSpecialtyFilters = exports.removeOneSpecialtyFilter = exports.setSpecialtyFilters = exports.setSearchTerm = void 0;
var store_1 = require("../store");
function setSearchTerm(state, search) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.search = search;
            return [2 /*return*/, newState];
        });
    });
}
exports.setSearchTerm = setSearchTerm;
function setSpecialtyFilters(state, specialtyCodes) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.specialties = specialtyCodes;
            return [2 /*return*/, newState];
        });
    });
}
exports.setSpecialtyFilters = setSpecialtyFilters;
function removeOneSpecialtyFilter(state, specialty) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.specialties = newState.filter.specialties.filter(function (spec) {
                return spec != specialty;
            });
            return [2 /*return*/, newState];
        });
    });
}
exports.removeOneSpecialtyFilter = removeOneSpecialtyFilter;
function clearSpecialtyFilters(state) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.specialties = [];
            return [2 /*return*/, newState];
        });
    });
}
exports.clearSpecialtyFilters = clearSpecialtyFilters;
function setOrganizationFilters(state, organizations) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.organizations = organizations;
            return [2 /*return*/, newState];
        });
    });
}
exports.setOrganizationFilters = setOrganizationFilters;
function removeOneOrganizationFilter(state, organization) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.organizations = newState.filter.organizations.filter(function (spec) {
                return spec != organization;
            });
            return [2 /*return*/, newState];
        });
    });
}
exports.removeOneOrganizationFilter = removeOneOrganizationFilter;
function clearBodyPartFilters(state) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.bodyParts = [];
            return [2 /*return*/, newState];
        });
    });
}
exports.clearBodyPartFilters = clearBodyPartFilters;
function setBodyPartFilters(state, bodyParts) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.bodyParts = bodyParts;
            return [2 /*return*/, newState];
        });
    });
}
exports.setBodyPartFilters = setBodyPartFilters;
function removeOneBodyPartFilter(state, bodyPart) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.bodyParts = newState.filter.bodyParts.filter(function (part) {
                return part != bodyPart;
            });
            return [2 /*return*/, newState];
        });
    });
}
exports.removeOneBodyPartFilter = removeOneBodyPartFilter;
function clearOrganizationFilters(state) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.organizations = [];
            return [2 /*return*/, newState];
        });
    });
}
exports.clearOrganizationFilters = clearOrganizationFilters;
function clearAllFilters(state) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.search = '';
            newState.filter.specialties = [];
            newState.filter.organizations = [];
            newState.filter.bodyParts = [];
            // newState.filter.showPublishedOnly = true;
            newState.filter.downloadAll = false;
            return [2 /*return*/, newState];
        });
    });
}
exports.clearAllFilters = clearAllFilters;
function setShowPublishedFilter(state, showPublishedOnly) {
    return __awaiter(this, void 0, void 0, function () {
        var newState;
        return __generator(this, function (_a) {
            newState = Object.assign({}, state);
            newState.filter.showPublishedOnly = showPublishedOnly;
            return [2 /*return*/, newState];
        });
    });
}
exports.setShowPublishedFilter = setShowPublishedFilter;
store_1.default.registerAction('setSearchTerm', setSearchTerm);
store_1.default.registerAction('setSpecialtyFilters', setSpecialtyFilters);
store_1.default.registerAction('removeOneSpecialtyFilter', removeOneSpecialtyFilter);
store_1.default.registerAction('clearSpecialtyFilters', clearSpecialtyFilters);
store_1.default.registerAction('setOrganizationFilters', setOrganizationFilters);
store_1.default.registerAction('removeOneOrganizationFilter', removeOneOrganizationFilter);
store_1.default.registerAction('clearOrganizationFilters', clearOrganizationFilters);
store_1.default.registerAction('setBodyPartFilters', setBodyPartFilters);
store_1.default.registerAction('removeOneBodyPartFilter', removeOneBodyPartFilter);
store_1.default.registerAction('clearBodyPartFilters', clearBodyPartFilters);
store_1.default.registerAction('clearAllFilters', clearAllFilters);
store_1.default.registerAction('setShowPublishedFilter', setShowPublishedFilter);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// node_modules
var sweetalert2_1 = require("sweetalert2");
var ErrorHandler = /** @class */ (function () {
    function ErrorHandler(error, type) {
        console.log(error.message);
        this.err = error;
        this.type = type;
        this.render(this.err);
    }
    ErrorHandler.prototype.render = function (err) {
        var errorMessage = 'Something went wrong';
        (0, sweetalert2_1.default)({
            title: 'Oops...',
            text: errorMessage,
            type: 'error',
        });
        return false;
    };
    return ErrorHandler;
}());
exports.default = ErrorHandler;

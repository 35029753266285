"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortReviewsValueConverter = void 0;
var SortReviewsValueConverter = /** @class */ (function () {
    function SortReviewsValueConverter() {
    }
    SortReviewsValueConverter.prototype.toView = function (reviews) {
        //sort so reviews without grades will be at the end of the array
        reviews.sort(function (a, b) {
            if (a.grade && !b.grade) {
                return -1;
            }
            else if (!a.grade && b.grade) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return reviews;
    };
    return SortReviewsValueConverter;
}());
exports.SortReviewsValueConverter = SortReviewsValueConverter;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewDetails = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var review_service_1 = require("services/review-service");
var element_service_1 = require("services/element-service");
var aurelia_router_1 = require("aurelia-router");
var ReviewDetails = /** @class */ (function () {
    function ReviewDetails(reviewService, elementService, ea, router) {
        this.reviewService = reviewService;
        this.elementService = elementService;
        this.ea = ea;
        this.router = router;
        this.focusedElementLoading = true;
        this.isLoading = false;
    }
    ReviewDetails.prototype.attached = function () {
        // review has to be focused
        // and if already have review data for review, dont get details again
        if (this.viewState.review.focusedReviewId && (!this.reviewData || this.reviewData.id != this.viewState.review.focusedReviewId)) {
            this.getDetails();
        }
    };
    // get data for display
    ReviewDetails.prototype.getDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.ea.publish('loading', true);
                        _a = this;
                        return [4 /*yield*/, this.reviewService.getReview(this.viewState.review.focusedReviewId)];
                    case 1:
                        _a.reviewData = _b.sent();
                        this.ea.publish('loading', false);
                        // default focus first element in set
                        this.focusElement(this.reviewData.set.elements[0].id);
                        return [2 /*return*/];
                }
            });
        });
    };
    ReviewDetails.prototype.focusElement = function (elementId) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!elementId) return [3 /*break*/, 2];
                        this.focusedElementLoading = true;
                        return [4 /*yield*/, this.elementService.getElementById(elementId)];
                    case 1:
                        data = _a.sent();
                        this.focusedElementLoading = false;
                        this.focusedElement = data.data;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ReviewDetails.prototype.BackToTable = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.router.navigateBack();
                return [2 /*return*/];
            });
        });
    };
    ReviewDetails = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)({
            selector: {
                viewState: function (store) { return store.state.pipe((0, operators_1.pluck)('view')); },
                authState: function (store) { return store.state.pipe((0, operators_1.pluck)('auth')); }
            }
        }),
        __metadata("design:paramtypes", [review_service_1.ReviewService, element_service_1.ElementService, aurelia_event_aggregator_1.EventAggregator, aurelia_router_1.Router])
    ], ReviewDetails);
    return ReviewDetails;
}());
exports.ReviewDetails = ReviewDetails;

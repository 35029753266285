"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTable = void 0;
var aurelia_framework_1 = require("aurelia-framework");
var aurelia_router_1 = require("aurelia-router");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var aurelia_dialog_1 = require("aurelia-dialog");
var download_cdes_1 = require("components/modals/download-cdes/download-cdes");
var BaseTable = /** @class */ (function () {
    function BaseTable(router, bindingengine, ea, dialogService) {
        this.router = router;
        this.bindingengine = bindingengine;
        this.ea = ea;
        this.dialogService = dialogService;
        this.selectedDownloads = [];
        this.tableData = [];
        this.paginationData = [];
        this.sortData = {
            sortBy: 'name',
            order: 'asc',
        };
        // pagination defaults
        this.pageNumber = 1;
    }
    BaseTable.prototype.attached = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // on page change get data for new page
                        this.paginationChangeSubscription = this.bindingengine
                            .propertyObserver(this, 'pageNumber')
                            .subscribe(function (newValue, oldValue) {
                            _this.getData();
                        });
                        // if subscription exists, dispose
                        if (this.forceReloadSubscription) {
                            this.forceReloadSubscription.dispose();
                        }
                        // force data to reload if change happens
                        this.forceReloadSubscription = this.ea.subscribe('forceReload', function (response) {
                            _this.tableData = [];
                        });
                        if (!(!this.tableData || !this.tableData.length)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getData()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BaseTable.prototype.detached = function () {
        this.paginationChangeSubscription.dispose();
    };
    // method to get data to fill table
    // should always be overwritten in child
    BaseTable.prototype.getData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.tableData = [];
                this.paginationData = [];
                return [2 /*return*/];
            });
        });
    };
    // set sortData on header clicks
    BaseTable.prototype.sortOnHeader = function (header, order) {
        if (order === void 0) { order = 'asc'; }
        if (this.sortData.sortBy == header) {
            this.sortData.order =
                this.sortData.order == 'desc' ? 'asc' : 'desc';
        }
        else {
            this.sortData.sortBy = header;
            this.sortData.order = order;
        }
        this.pageNumber = 1;
        this.getData();
    };
    // Function to select/deselect all CDE download checkboxes
    BaseTable.prototype.toggleDownloadSelectAll = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var target;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        target = event.target;
                        // Set downloadAll flag to true/false based on header checkbox
                        target.checked
                            ? (this.filterState.downloadAll = true)
                            : (this.filterState.downloadAll = false);
                        // Set downloadAll flag to true/false based on header checkbox
                        return [4 /*yield*/, this.getData()];
                    case 1:
                        // Set downloadAll flag to true/false based on header checkbox
                        _a.sent();
                        return [4 /*yield*/, this.cdeMapper()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // Helper function to help with toggle all since the CDE data (table data) for allCdes view comes in nested
    // The Auerlia binding engine bugs out when you try to use its native checkbox binding engine with select all
    // So we have to flatten the data for elements to solve this issue
    BaseTable.prototype.cdeMapper = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cdeSlices;
            return __generator(this, function (_a) {
                cdeSlices = this.tableData.slice();
                // Flatten for elements ( indicated by the lack of id in the first layer of the data array)
                cdeSlices = cdeSlices.map(function (cde) {
                    if (!cde.id) {
                        return cde.elements[0];
                    }
                    else {
                        return cde;
                    }
                });
                // If downloadAll is true, all cdeSlices into selectedDownloads   :	else downloadAll is false, clear selectedDownloads
                this.filterState.downloadAll
                    ? (this.selectedDownloads = cdeSlices)
                    : (this.selectedDownloads = []);
                return [2 /*return*/];
            });
        });
    };
    BaseTable.prototype.openDownloadModal = function () {
        var _this = this;
        this.dialogService
            .open({
            viewModel: download_cdes_1.DownloadCdes,
            model: this.selectedDownloads,
            lock: true,
        })
            .whenClosed(function (response) {
            if (!response.wasCancelled) {
                // reset selected downloads
                _this.selectedDownloads = [];
                // reset downloadAll flag
                _this.filterState.downloadAll = false;
            }
        });
    };
    BaseTable = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)({
            selector: {
                viewState: function (store) { return store.state.pipe((0, operators_1.pluck)('view')); },
                filterState: function (store) { return store.state.pipe((0, operators_1.pluck)('filter')); },
            },
        }),
        __metadata("design:paramtypes", [aurelia_router_1.Router,
            aurelia_framework_1.BindingEngine,
            aurelia_event_aggregator_1.EventAggregator,
            aurelia_dialog_1.DialogService])
    ], BaseTable);
    return BaseTable;
}());
exports.BaseTable = BaseTable;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.About = void 0;
var aurelia_router_1 = require("aurelia-router");
var aurelia_framework_1 = require("aurelia-framework");
var view_1 = require("store/actions/view");
var aurelia_store_1 = require("aurelia-store");
var operators_1 = require("rxjs/operators");
var config_1 = require("../../../config");
var about_service_1 = require("services/about-service");
var About = /** @class */ (function () {
    function About(router, aboutService) {
        this.router = router;
        this.aboutService = aboutService;
        this.isLoading = false;
        this.hostUrl = window.location.protocol + "//" + window.location.host;
        this.page = 'overview';
        this.baseApiUrl = config_1.Configuration.getBaseURI();
        this.members = [
            {
                name: "Namita S. Gandhi, MD",
                role: "MSc Co-Chair RSNA",
                location: "Cleveland, OH"
            },
            {
                name: "Tarik K. Alkasab, MD",
                role: "PhD, Co-Chair ACR",
                location: "Boston, MA"
            },
            {
                name: "Patricia Balthazar, MD",
                role: "Member RSNA",
                location: "Atlanta, GA"
            },
            {
                name: "Melissa A. Davis, MD, MBA",
                role: "Member RSNA",
                location: "Atlanta, GA"
            },
            {
                name: "Madhavi Duvvuri, MD, MS",
                role: "Member RSNA",
                location: "San Francisco, CA"
            },
            {
                name: "Marta E. Heilbrun, MD, MS",
                role: "Member RSNA",
                location: "Murray, UT"
            },
            {
                name: "Michael Hood, MD",
                role: "Member ACR",
                location: "Boston, MA"
            },
            {
                name: "Charles E. Kahn, Jr, MD, MS",
                role: "Member RSNA",
                location: "Philadelphia, PA"
            },
            {
                name: "Ashley E. Prosper, MD",
                role: "Member ACR",
                location: "Los Angeles, CA"
            },
            {
                name: "Chintan Shah, MD, MS",
                role: "Member RSNA",
                location: "Shaker Heights, OH"
            },
            {
                name: "Alexander Towbin, MD",
                role: "Member ACR",
                location: "Cincinnati, OH"
            },
            {
                name: "Jacob J. Visser, MD, PhD",
                role: "Member at Large RSNA",
                location: "Rotterdam, Netherlands"
            },
            {
                name: "Daniel J. Vreeman, MS",
                role: "Member at Large RSNA",
                location: "Research Triangle Park, NC"
            },
            {
                name: "Adam E. Flanders, MD",
                role: "Board Liaison RSNA",
                location: "Philadelphia, PA"
            }
        ];
        /** Elements Documentation variables
         * We're using the below values for urls to query the API and strings to hold the json responses
         **/
        // Element example 1: base or default search
        this.elementsApiUrl = this.baseApiUrl + '/elements';
        this.elementsBaseApiResponse = '';
        // Element example 2: search on a string
        this.elementsSearchApiUrl = this.elementsApiUrl + '?search=cisternal';
        this.elementsSearchApiResponse = '';
        // Element example 3: search for a specific element
        this.specificElementApiUrl = this.elementsApiUrl + '/45';
        this.specificElementApiResponse = '';
        // Element example 4: search for a specific elements set
        this.specificElementsSetsApiUrl = this.elementsApiUrl + '/43/sets';
        this.specificElementsSetsApiResponse = '';
        /** Codes Documentation variables
         * We're using the below values for urls to query the API and strings to hold the json responses
         **/
        // Code example 1: base or default search
        this.codesApiUrl = this.baseApiUrl + '/codes';
        this.codesApiResponse = '';
        // Code example 2: get specific system
        this.systemApiUrl = this.codesApiUrl + '/RADLEX';
        this.systemApiResponse = '';
        // Code example 3: get specific code
        this.systemCodeApiUrl = this.codesApiUrl + '/RADLEX/RID28662';
        this.systemCodeApiResponse = '';
        // Code example 4: get specific code and its data elements
        this.specificCodesElementsApiUrl = this.codesApiUrl + '/RADLEX/RID28662/elements';
        this.specificCodesElementsApiResponse = '';
        // Code example 5: get specific code and its data element values
        this.systemCodeElementValuesApiUrl = this.codesApiUrl + '/RADLEX/RID5824/values';
        this.systemCodeElementValuesApiResponse = '';
        /** Sets Documentation variables
         * We're using the below values for urls to query the API and strings to hold the json responses
         **/
        // Set example 1: base or default search
        this.setsApiUrl = this.baseApiUrl + '/sets';
        this.setsApiUrlResponse = '';
        // Set example 2: search on a string
        this.setSearchApiUrl = this.setsApiUrl + '?search=acute';
        this.setSearchApiResponse = '';
        // Set example 3: search for a specific set
        this.specificSetApiUrl = this.setsApiUrl + '/5';
        this.specificSetApiResponse = '';
        // Set example 4: search for a specific sets element
        this.specificSetsElementsApiUrl = this.setsApiUrl + '/5/elements';
        this.specificSetsElementsApiResponse = '';
        //RadElement CDE Permission Request
        this.firstNameText = '';
        this.lastNameText = '';
        this.emailText = '';
        this.phoneNumberText = '';
        this.organizationText = '';
        this.organizationTypeRadioValue = '';
        this.addressLine1Text = '';
        this.addressLine2Text = '';
        this.cityText = '';
        this.stateText = '';
        this.zipText = '';
        this.intendedUseOfRadElementCDEsTextarea = '';
        this.requestFailed = false;
        this.requestSuccessful = false;
        this.requestResponse = false;
    }
    // if ids are in url, focus that set/element.
    // if elementid and setid are set the element will be displayed and pressing back will display the set
    About.prototype.activate = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15;
            return __generator(this, function (_16) {
                switch (_16.label) {
                    case 0:
                        if (params.page) {
                            this.page = params.page;
                        }
                        else {
                            this.page = 'overview';
                        }
                        if (params.subPage) {
                            this.subPage = params.subPage;
                        }
                        else {
                            this.subPage = null;
                        }
                        // fetch api responses for doc examples
                        this.isLoading = true;
                        _a = this.subPage;
                        switch (_a) {
                            case 'codes': return [3 /*break*/, 1];
                            case 'sets': return [3 /*break*/, 7];
                            case 'elements': return [3 /*break*/, 12];
                        }
                        return [3 /*break*/, 17];
                    case 1:
                        // fetch data for codes requests;
                        _b = this;
                        _d = (_c = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.codesApiUrl)];
                    case 2:
                        // fetch data for codes requests;
                        _b.codesApiResponse = _d.apply(_c, [_16.sent(), null, "\t"]).trim();
                        _e = this;
                        _g = (_f = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.systemApiUrl)];
                    case 3:
                        _e.systemApiResponse = _g.apply(_f, [_16.sent(), null, "\t"]).trim();
                        _h = this;
                        _k = (_j = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.systemCodeApiUrl)];
                    case 4:
                        _h.systemCodeApiResponse = _k.apply(_j, [_16.sent(), null, "\t"]).trim();
                        _l = this;
                        _o = (_m = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.specificCodesElementsApiUrl)];
                    case 5:
                        _l.specificCodesElementsApiResponse = _o.apply(_m, [_16.sent(), null, "\t"]).trim();
                        _p = this;
                        _r = (_q = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.systemCodeElementValuesApiUrl)];
                    case 6:
                        _p.systemCodeElementValuesApiResponse = _r.apply(_q, [_16.sent(), null, "\t"]).trim();
                        return [3 /*break*/, 18];
                    case 7:
                        // fetch data for sets requests;
                        _s = this;
                        _u = (_t = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.setsApiUrl)];
                    case 8:
                        // fetch data for sets requests;
                        _s.setsApiUrlResponse = _u.apply(_t, [_16.sent(), null, "\t"]).trim();
                        _v = this;
                        _x = (_w = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.setSearchApiUrl)];
                    case 9:
                        _v.setSearchApiResponse = _x.apply(_w, [_16.sent(), null, "\t"]).trim();
                        _y = this;
                        _0 = (_z = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.specificSetApiUrl)];
                    case 10:
                        _y.specificSetApiResponse = _0.apply(_z, [_16.sent(), null, "\t"]).trim();
                        _1 = this;
                        _3 = (_2 = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.specificSetsElementsApiUrl)];
                    case 11:
                        _1.specificSetsElementsApiResponse = _3.apply(_2, [_16.sent(), null, "\t"]).trim();
                        return [3 /*break*/, 18];
                    case 12:
                        // fetch data for elements requests;
                        _4 = this;
                        _6 = (_5 = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.elementsApiUrl)];
                    case 13:
                        // fetch data for elements requests;
                        _4.elementsBaseApiResponse = _6.apply(_5, [_16.sent(), null, "\t"]).trim();
                        _7 = this;
                        _9 = (_8 = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.elementsSearchApiUrl)];
                    case 14:
                        _7.elementsSearchApiResponse = _9.apply(_8, [_16.sent(), null, "\t"]).trim();
                        _10 = this;
                        _12 = (_11 = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.specificElementApiUrl)];
                    case 15:
                        _10.specificElementApiResponse = _12.apply(_11, [_16.sent(), null, "\t"]).trim();
                        _13 = this;
                        _15 = (_14 = JSON).stringify;
                        return [4 /*yield*/, this.aboutService.sendDocumentationRequest(this.specificElementsSetsApiUrl)];
                    case 16:
                        _13.specificElementsSetsApiResponse = _15.apply(_14, [_16.sent(), null, "\t"]).trim();
                        return [3 /*break*/, 18];
                    case 17:
                        {
                            return [3 /*break*/, 18];
                        }
                        _16.label = 18;
                    case 18:
                        this.isLoading = false;
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(view_1.defocusSet)()];
                    case 19:
                        _16.sent();
                        return [4 /*yield*/, (0, aurelia_store_1.dispatchify)(view_1.defocusElement)()];
                    case 20:
                        _16.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    About.prototype.changePage = function (page, event) {
        this.router.navigate("about/".concat(page));
        if (event) {
            event.stopPropagation();
        }
    };
    About.prototype.toggleExpansion = function (id) {
        var targetButton = document.getElementById(id);
        var arrowIcon = targetButton === null || targetButton === void 0 ? void 0 : targetButton.children[0];
        if (arrowIcon === null || arrowIcon === void 0 ? void 0 : arrowIcon.classList.contains('fa-plus')) {
            arrowIcon.classList.remove('fa-plus');
            arrowIcon.classList.add('fa-minus');
        }
        else {
            arrowIcon === null || arrowIcon === void 0 ? void 0 : arrowIcon.classList.add('fa-plus');
            arrowIcon === null || arrowIcon === void 0 ? void 0 : arrowIcon.classList.remove('fa-minus');
        }
    };
    About.prototype.copyToClipboard = function (id) {
        var copyText = document.getElementById(id);
        if (!copyText) {
            return;
        }
        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText === null || copyText === void 0 ? void 0 : copyText.innerText);
        alert('Copied to clipboard');
    };
    About.prototype.scrollTo = function (id) {
        var el = document.getElementById(id);
        if (el) {
            el.scrollIntoView();
        }
    };
    About.prototype.printPage = function (page) {
        // find the div holding the guide we want to print, then place that in a new window and open print dialog for the chosen content
        var prtContent = document.getElementById(page);
        if (prtContent) {
            var WinPrint = window.open('');
            if (WinPrint) {
                WinPrint.document.write(prtContent.innerHTML);
                WinPrint.document.close();
                WinPrint.focus();
                WinPrint.print(); // open print prompt window to select print destination, orientation etc
                WinPrint.close(); // close new window after saved/printed or canceled
            }
        }
    };
    About.prototype.sendCDERequest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.isNullOrWhiteSpace(this.firstNameText) ||
                            this.isNullOrWhiteSpace(this.lastNameText) ||
                            this.isNullOrWhiteSpace(this.emailText) ||
                            this.isNullOrWhiteSpace(this.phoneNumberText) ||
                            this.isNullOrWhiteSpace(this.organizationText) ||
                            this.isNullOrWhiteSpace(this.organizationTypeRadioValue) ||
                            this.isNullOrWhiteSpace(this.addressLine1Text) ||
                            this.isNullOrWhiteSpace(this.cityText) ||
                            this.isNullOrWhiteSpace(this.stateText) ||
                            this.isNullOrWhiteSpace(this.zipText) ||
                            this.isNullOrWhiteSpace(this.intendedUseOfRadElementCDEsTextarea))) return [3 /*break*/, 1];
                        this.requestFailed = true;
                        return [3 /*break*/, 3];
                    case 1:
                        _a = this;
                        return [4 /*yield*/, this.aboutService.sendCDEPermissionRequest(this.firstNameText, this.lastNameText, this.emailText, this.phoneNumberText, this.organizationText, this.organizationTypeRadioValue, this.addressLine1Text, this.cityText, this.stateText, this.zipText, this.intendedUseOfRadElementCDEsTextarea, this.isNullOrWhiteSpace(this.addressLine2Text)
                                ? undefined
                                : this.addressLine2Text)];
                    case 2:
                        _a.requestResponse =
                            _b.sent();
                        if (this.requestResponse) {
                            this.requestSuccessful = true;
                            this.firstNameText = '';
                            this.lastNameText = '';
                            this.emailText = '';
                            this.phoneNumberText = '';
                            this.organizationText = '';
                            this.addressLine1Text = '';
                            this.cityText = '';
                            this.stateText = '';
                            this.zipText = '';
                            this.intendedUseOfRadElementCDEsTextarea = '';
                        }
                        else {
                            this.requestFailed = true;
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    About.prototype.isNullOrWhiteSpace = function (str) {
        return !str || str.length === 0 || /^\s*$/.test(str);
    };
    About = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)({
            selector: {
                element: function (store) { return store.state.pipe((0, operators_1.pluck)('element')); },
                set: function (store) { return store.state.pipe((0, operators_1.pluck)('set')); },
                authState: function (store) { return store.state.pipe((0, operators_1.pluck)('auth')); },
            },
        }),
        __metadata("design:paramtypes", [aurelia_router_1.Router, about_service_1.aboutService])
    ], About);
    return About;
}());
exports.About = About;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJwt = void 0;
var parseJwt = function (token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    }
    catch (e) {
        return null;
    }
};
exports.parseJwt = parseJwt;

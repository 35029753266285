"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCookie = exports.deleteCookie = exports.getCookie = void 0;
function getCookie(name) {
    try {
        name += "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
    catch (error) {
        return false;
    }
}
exports.getCookie = getCookie;
function deleteCookie(name) {
    document.cookie = "".concat(name, "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;");
}
exports.deleteCookie = deleteCookie;
function setCookie(name, data) {
    document.cookie = "".concat(name, "=").concat(data, ";path=/;");
}
exports.setCookie = setCookie;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BooleanToStringValueConverter = void 0;
var BooleanToStringValueConverter = /** @class */ (function () {
    function BooleanToStringValueConverter() {
    }
    BooleanToStringValueConverter.prototype.toView = function (value) {
        if (value == 0) {
            return 'No';
        }
        else if (value == 1) {
            return 'Yes';
        }
        else {
            return 'N/A';
        }
    };
    return BooleanToStringValueConverter;
}());
exports.BooleanToStringValueConverter = BooleanToStringValueConverter;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferenceTypeFilterValueConverter = void 0;
var ReferenceTypeFilterValueConverter = /** @class */ (function () {
    function ReferenceTypeFilterValueConverter() {
    }
    ReferenceTypeFilterValueConverter.prototype.toView = function (dataArray, referenceType) {
        if (dataArray) {
            //a bit hacky
            //probably want to include reference type in the data coming back from the api
            var imageExtensions_1 = [
                'jpg',
                'JPG',
                'png',
                'PNG',
                'jpeg',
                'JPEG',
            ];
            switch (referenceType) {
                case 'text':
                    dataArray = dataArray.filter(function (ref, index, arr) {
                        var urlSplit = [];
                        if (ref.url) {
                            urlSplit = ref.url.split('.');
                        }
                        //return true if last part after '.' doesnt match image extension
                        return !imageExtensions_1.includes(urlSplit[urlSplit.length - 1]);
                    });
                    break;
                case 'image':
                    dataArray = dataArray.filter(function (ref, index, arr) {
                        var urlSplit = [];
                        if (ref.url) {
                            urlSplit = ref.url.split('.');
                        }
                        //return true if last part after '.' is an image extension
                        return imageExtensions_1.includes(urlSplit[urlSplit.length - 1]);
                    });
                    break;
            }
        }
        return dataArray;
    };
    return ReferenceTypeFilterValueConverter;
}());
exports.ReferenceTypeFilterValueConverter = ReferenceTypeFilterValueConverter;

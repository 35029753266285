"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.App = void 0;
var aurelia_framework_1 = require("aurelia-framework");
/**
 * RadElement 2.0
 *
 * @author Michael Crabtree
 * @author Derrick Lottich
 */
var aurelia_router_1 = require("aurelia-router");
var aurelia_pal_1 = require("aurelia-pal");
var config_1 = require("./config");
var route_scroll_page_1 = require("./utils/route-scroll-page");
var aurelia_event_aggregator_1 = require("aurelia-event-aggregator");
var aurelia_store_1 = require("aurelia-store");
var store_1 = require("./store/store");
var state_1 = require("./store/state");
var App = /** @class */ (function () {
    function App(router, ea) {
        this.router = router;
        this.ea = ea;
        var local = window.localStorage.getItem('D593FDC3C6DD4D0320A7B1F5BF419BF2');
        if (local) {
            local = JSON.parse(local);
            if (!(0, state_1.validateState)(local)) {
                window.localStorage.removeItem('D593FDC3C6DD4D0320A7B1F5BF419BF2');
            }
        }
        // register actions for local storage middleware
        store_1.default.registerAction('Rehydrate', aurelia_store_1.rehydrateFromLocalStorage);
        store_1.default.registerMiddleware(aurelia_store_1.localStorageMiddleware, aurelia_store_1.MiddlewarePlacement.After, { key: 'D593FDC3C6DD4D0320A7B1F5BF419BF2' });
        // get data in local storage and put it in state
        store_1.default.dispatch(aurelia_store_1.rehydrateFromLocalStorage, 'D593FDC3C6DD4D0320A7B1F5BF419BF2');
    }
    App.prototype.unbind = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                localStorage.clear();
                return [2 /*return*/];
            });
        });
    };
    App.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            var script;
            return __generator(this, function (_a) {
                script = document.createElement('script');
                script.src = 'https://cdn.rsna.org/shared-javascript/src/js/cookiePolicy.js';
                document.body.appendChild(script);
                return [2 /*return*/];
            });
        });
    };
    App.prototype.configureRouter = function (config, router) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                config.title = config_1.Configuration.getApplicationName();
                config.options.pushState = true;
                config.options.root = '/';
                config.addPostRenderStep(route_scroll_page_1.RouteScrollPage);
                config.map([
                    {
                        route: [
                            'home',
                            'home/:tab',
                            'home/:tab/set/:setId',
                            'home/:tab/element/:elementId',
                            'home/:tab/set/:setId/element/:elementId'
                        ],
                        // Hotloading
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/pages/home/home', 'home'),
                        title: 'Home',
                        name: 'home',
                        settings: {
                            scrollToTop: true,
                        },
                    },
                    {
                        route: [
                            'about/',
                            'about/:page',
                            'about/:page/:subPage'
                        ],
                        // Hotloading
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/pages/about/about', 'about'),
                        title: 'About',
                        name: 'about',
                        settings: {
                            scrollToTop: true,
                        },
                    },
                    {
                        route: [
                            'about/demo',
                        ],
                        // Hotloading
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/pages/about/demo/demo', 'demo'),
                        title: 'Demo',
                        name: 'demo',
                        settings: {
                            scrollToTop: true,
                        },
                    },
                    {
                        route: [
                            'profile',
                            'profile/:pageName',
                            'profile/:pageName/set/:setId?',
                            'profile/:pageName/element/:elementId?',
                            'profile/:pageName/review/:reviewId',
                            'profile/:pageName/review/:reviewId/assignment/:assignmentId'
                        ],
                        // Hotloading
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/pages/profile/profile', 'profile'),
                        title: 'Profile',
                        name: 'profile',
                        settings: {
                            scrollToTop: true,
                        },
                    },
                    {
                        route: [
                            'login'
                        ],
                        name: 'login',
                        moduleId: aurelia_pal_1.PLATFORM.moduleName('./components/login/login', 'login')
                    }
                ]);
                config.mapUnknownRoutes(aurelia_pal_1.PLATFORM.moduleName('./components/pages/home/home'));
                this.router = router;
                return [2 /*return*/];
            });
        });
    };
    App = __decorate([
        aurelia_framework_1.autoinject,
        (0, aurelia_store_1.connectTo)(),
        __metadata("design:paramtypes", [aurelia_router_1.Router, aurelia_event_aggregator_1.EventAggregator])
    ], App);
    return App;
}());
exports.App = App;
